import { Button } from 'amphitheatre';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import CallToAction from 'src/Components/CallToAction';
import Container from 'src/Components/ContentContainer';
import SEO from 'src/Components/SEO';
import Layout from 'src/Layout';
import * as Base from 'src/page-styles/base.styled';
import * as Hero from 'src/page-styles/hero.styled';
import styled from 'styled-components';
import { MdApi, MdDashboard } from 'react-icons/md';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FaChrome, FaEdge, FaFirefoxBrowser, FaHammer } from 'react-icons/fa';
import { ImSafari } from 'react-icons/im';
import { Typography } from 'amphitheatre';
const { Header } = Typography;

const Features = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    row-gap: 5rem;
  }
`;

const Icon = styled.div`
  border: 4px solid ${({ theme }) => theme.primary500};
  border-radius: 100%;
  color: ${({ theme }) => theme.grey300};

  font-size: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 64px;
  width: 64px;
  min-height: 64px;
  min-width: 64px;

  margin-right: 1rem;

  svg path {
    stroke: ${({ theme }) => theme.grey300};
  }

  @media only screen and (min-width: 500px) {
    font-size: 3rem;

    height: 96px;
    width: 96px;
    min-height: 96px;
    min-width: 96px;
  }

  @media only screen and (min-width: 1024px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 1200px) {
    height: 124px;
    width: 124px;
    min-height: 124px;
    min-width: 124px;
  }
`;

const IconText = styled.div`
  flex-grow: 1;
`;

const IconHeader = styled.div`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 0.875rem;
  font-weight: 500;

  letter-spacing: 1px;

  @media only screen and (min-width: 1024px) {
    font-size: 1rem;
    text-align: center;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 1.125rem;
  }
`;

const IconSubheader = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 0.875rem;
  margin-top: 1rem;

  @media only screen and (min-width: 1024px) {
    text-align: center;
    font-size: 0.875rem;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 1rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  width: 100%;
  height: 20rem;

  @media only screen and (min-width: 1024px) {
    flex-flow: column;
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  img {
    object-fit: cover;
    height: 296px;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;

    img {
      height: 512px;
    }
  }
`;

const GalleryImageOverlayText = styled.div`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.4rem;
  font-weight: 500;
  position: absolute;
  text-align: center;
  bottom: 0.75em;
  left: 50%;
  transform: translateX(-50%);
`;

const GalleryImageWrapper = styled.div`
  position: relative;
  .gatsby-image-wrapper-constrained {
    display: block;
  }
`;

const Logos = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 3rem;
  text-align: center;

  svg:not(:first-child) {
    margin-left: 2rem;
  }

  @media only screen and (min-width: 768px) {
    font-size: 5rem;

    svg:not(:first-child) {
      margin-left: 5rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    font-size: 6rem;
  }
`;

function MDAT(): JSX.Element {
  return (
    <>
      <SEO
        title="MDAT | Saber Astronautics"
        description="Mission Design and Analysis Toolkit."
        url="https://www.saberastro.com/mdat"
      />
      <Layout>
        <Hero.Wrapper>
          <Hero.Cover />
          <Hero.Cover />
          <StaticImage
            className="hero-image"
            src="../images/mdat/Background.jpg"
            alt="M.D.A.T. background"
          />
          <Hero.Text>
            <Hero.MajorHeader>
              <Base.Yellow>Mission Design and Analysis Toolkit</Base.Yellow>
            </Hero.MajorHeader>
            <Hero.Header style={{ marginBottom: '1rem' }}>
              Mission Forge • Report Generation • API • Dashboards
            </Hero.Header>
            <Hero.Header>
              <a href="mailto:mission.design@saberastro.com">
                <Button size="xlg">Get in touch</Button>
              </a>
            </Hero.Header>
          </Hero.Text>
        </Hero.Wrapper>
        <Container.Dark>
          <Base.Gap />
          <Hero.MajorHeader>
            <Base.Yellow>Tools</Base.Yellow>
          </Hero.MajorHeader>
          <Base.Gap />
          <Base.Paragraph>
            <Features>
              <div>
                <IconWrapper>
                  <Icon>
                    <FaHammer />
                  </Icon>
                  <IconText>
                    <IconHeader>SABER MISSION FORGE</IconHeader>
                    <IconSubheader>
                      Design missions and spacecraft quickly and intuitively
                    </IconSubheader>
                  </IconText>
                </IconWrapper>
              </div>
              <div>
                <IconWrapper>
                  <Icon>
                    <HiOutlineDocumentReport />
                  </Icon>
                  <IconText>
                    <IconHeader>REPORT GENERATION</IconHeader>
                    <IconSubheader>
                      Automatically generate detailed feasibility reports with a
                      single click.
                    </IconSubheader>
                  </IconText>
                </IconWrapper>
              </div>
              <div>
                <IconWrapper>
                  <Icon>
                    <MdApi />
                  </Icon>
                  <IconText>
                    <IconHeader>MISSION DESIGN API</IconHeader>
                    <IconSubheader>
                      Access individual calculators through a web-based API
                    </IconSubheader>
                  </IconText>
                </IconWrapper>
              </div>
              <div>
                <IconWrapper>
                  <Icon>
                    <MdDashboard />
                  </Icon>
                  <IconText>
                    <IconHeader>DASHBOARDS</IconHeader>
                    <IconSubheader>
                      Fine-tune and optimize designs using Dashboards.
                    </IconSubheader>
                  </IconText>
                </IconWrapper>
              </div>
            </Features>
          </Base.Paragraph>
        </Container.Dark>
        <Container.Dark>
          <StaticImage
            src="../images/mdat/Forge_intro.png"
            alt="Mission Forge Intro image"
          />
          <Base.Gap />
        </Container.Dark>
        <Container.Dark>
          <Logos>
            <FaFirefoxBrowser />
            <FaChrome />
            <ImSafari />
            <FaEdge />
          </Logos>
          <Header style={{ textAlign: 'center' }}>
            <Base.Yellow>Cross platform support</Base.Yellow> across major
            browsers
          </Header>
          <Base.Gap />
        </Container.Dark>
        <Gallery>
          <GalleryImageWrapper>
            <StaticImage
              src="../images/mdat/Forge_1.png"
              alt="Mission Forge Image 1"
            />
            <GalleryImageOverlayText>
              Design orbits with ease
            </GalleryImageOverlayText>
          </GalleryImageWrapper>
          <GalleryImageWrapper>
            <StaticImage
              src="../images/mdat/Forge_2.png"
              alt="Mission Forge Image 2"
            />
            <GalleryImageOverlayText>
              Perform coverage previews
            </GalleryImageOverlayText>
          </GalleryImageWrapper>
        </Gallery>
        <Gallery>
          <GalleryImageWrapper>
            <StaticImage
              src="../images/mdat/Forge_3.png"
              alt="Mission Forge Image 3"
            />
            <GalleryImageOverlayText>
              Show overpass estimates
            </GalleryImageOverlayText>
          </GalleryImageWrapper>
          <GalleryImageWrapper>
            <StaticImage
              src="../images/mdat/Forge_4.png"
              alt="Mission Forge Image 4"
            />
            <GalleryImageOverlayText>
              Plan for the space environment
            </GalleryImageOverlayText>
          </GalleryImageWrapper>
        </Gallery>
        <Container.Dark style={{ paddingTop: '10em' }}>
          <center>
            <StaticImage
              src="../images/aws-badges/saber-aws-partner.svg"
              alt="AWS Partner Badge"
              style={{ margin: '2em', alignSelf: 'left' }}
            />
            <StaticImage
              src="../images/aws-badges/saber-aws-qualified-software.svg"
              alt="AWS Qualified Software Badge"
              style={{ margin: '2em', alignSelf: 'right' }}
            />
            <StaticImage
              src="../images/aws-badges/saber-public-sector-badge.svg"
              alt="AWS Public Sector Partner Badge"
              style={{ margin: '2em', alignSelf: 'right' }}
            />
          </center>
          <Header style={{ textAlign: 'center' }}>
            <Base.Yellow>Built on AWS</Base.Yellow>
          </Header>
          <Base.Gap />
        </Container.Dark>
        <CallToAction
          header="Ready to Forge your own mission?"
          linkText="Contact us"
          to="/contact-us/"
        />
      </Layout>
    </>
  );
}

export default MDAT;
